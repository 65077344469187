<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="呼叫号码">
          <el-input v-model="dataForm.callPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="接听状态">
          <el-input v-model="dataForm.callStateDesc" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="成功" value="2"></el-option>
            <el-option label="失败" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small"
                v-loading="dataListLoading"
                style="width: 100%;">
        <el-table-column prop="driverName" label="司机姓名">
        </el-table-column>
        <el-table-column prop="callPhone" label="呼叫号码">
        </el-table-column>
        <el-table-column prop="vehiclePlate" label="车牌号">
        </el-table-column>
        <el-table-column prop="status" label="调用状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" size="small" type="danger">失败</el-tag>
            <el-tag v-else size="small" type="success">成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="resMessage" label="响应信息">
        </el-table-column>
        <el-table-column prop="callStateDesc" label="接听状态">
        </el-table-column>
        <el-table-column prop="callDuration" label="通话时长(秒)">
        </el-table-column>
        <el-table-column prop="callGmtTime" label="呼叫时间">
        </el-table-column>
        <el-table-column prop="callStartTime" label="接听时间">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        callPhone: null,
        status: null,
        callStateDesc: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      getIndex: -1
    }
  },
  activated () {
    this.getDataList()
  },

  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/notice-call/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'callPhone': this.dataForm.callPhone,
          'status': this.dataForm.status,
          'callStateDesc': this.dataForm.callStateDesc
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
